import React, { useContext, useState, useEffect } from "react";
import "./header.scss";
import { AuthContext } from "../../../../context/AuthContext";
import Cookies from "js-cookie";
import { getPlayerBalance } from "../../../../services/player-api/PlayerServices";

function Header() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isVertical, balance, setBalance, settingsVisible, setSettingsVisible } = useContext(AuthContext);

  function formatNumber(value) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }

  useEffect(() => {
    getBalance();
  }, []);

  const getBalance = async () => {
    try {
      setIsLoading(true);
      const result = await getPlayerBalance({
        playerID: Cookies.get("PlayerID"),
      });
      setBalance(result.wallet);
      return;
    } catch (err) {
      setError(err.message || "Failed to get Balance");
    } finally {
      setIsLoading(false);
    }
  };

  if (isVertical) {
    return (
      <div className="v-header-container">
        <div className="logo-container">
          <div className="balance-btn-container">
            <img className="balance-btn-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/balance.png" alt="" />
            <p className="balance-text">{formatNumber(balance)}</p>
          </div>
          <img className="logo-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/logo.png" alt="" />
          <div className="player-btn-container">
            <img className="player-btn-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/playerid.png" alt="" />
            <p className="player-text">{Cookies.get("UserName")}</p>
          </div>
        </div>
        {/* 
                <div className="menu-container">
                    <div className="player-btn-container">
                        <img className="player-btn-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/playerid.png" alt="" />
                        <p className="player-text">{Cookies.get("UserName")}</p>
                    </div>
                    <div className="balance-btn-container">
                        <img className="balance-btn-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/balance.png" alt="" />
                        <p className="balance-text">{formatNumber(balance)}</p>
                    </div>
                    <div className="refresh-text-container">
                        <img
                            className="refresh-text-img"
                            src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/refreshtext2.png"
                            alt=""
                        />
                    </div>
                    <div className="refresh-btn-container">
                        <img className="refresh-btn-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/refreshbtb.png" alt="" />
                    </div>
                </div>
                */}
      </div>
    );
  } else {
    return (
      <div className="h-header-container">
        <div style={{ width: "15% " }}></div>
        <div className="balance-container">
          {/* 
                    <div className="refresh-btn-container">
                        <img className="refresh-btn-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/refreshbtb.png" alt="" />
                    </div>
                    <div className="refresh-text-container">
                        <img className="refresh-text-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/refreshtext.png" alt="" />
                    </div>
                    */}
          <div className="balance-btn-container">
            <img className="balance-btn-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/balance.png" alt="" />
            <p className="balance-text">{formatNumber(balance)}</p>
          </div>
        </div>
        <div className="logo-container">
          <img className="logo-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/logo.png" alt="" />
        </div>
        <div className="menu-container">
          <div className="player-btn-container">
            <img className="player-btn-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/playerid.png" alt="" />
            <p className="player-text">{Cookies.get("UserName")}</p>
          </div>
          <div
            className="setting-btn-container"
            onClick={() => {
              setSettingsVisible(!settingsVisible);
            }}
          >
            <img className="setting-btn-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/setting.png" alt="" />
          </div>
          {/* 
                    <div className="logout-btn-container">
                        <img className="logout-btn-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/language.png" alt="" />
                    </div>
                    */}
        </div>
      </div>
    );
  }
}

export default Header;
