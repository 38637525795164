import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";

const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const audioRef = useRef(new Audio("https://game-platform.sgp1.digitaloceanspaces.com/bwg/music/BackgroundMusic.mp3")); // Make sure this URL is properly quoted

  const [volume, setVolume] = useState(1); // Set default volume to 1 (max)

  // Set the loop property to true for continuous playback
  audioRef.current.loop = true;

  const playAudio = () => {
    audioRef.current.play().catch((error) => {
      console.log("Playback was prevented by the browser:", error);
    });
  };

  const pauseAudio = () => {
    audioRef.current.pause();
  };

  const muteAudio = () => {
    audioRef.current.muteAudio();
  };

  const unMutedAudio = () => {
    audioRef.current.unMutedAudio();
  };

  const changeVolume = (newVolume) => {
    audioRef.current.volume = newVolume;
    setVolume(newVolume); // Update state for volume
  };

  // Change the music by updating the audio source URL
  const cancelMusic = () => {
    audioRef.current.src = null;
    pauseAudio(); // Start playing the new music immediately
  };

  // Change the music by updating the audio source URL
  const angpaoMusicOn = () => {
    audioRef.current.src = "https://game-platform.sgp1.digitaloceanspaces.com/bwg/music/AngpaoMusic.mp3";
    playAudio(); // Start playing the new music immediately
  };

  // Change the music by updating the audio source URL
  const backgroundMusicOn = () => {
    audioRef.current.src = "https://game-platform.sgp1.digitaloceanspaces.com/bwg/music/BackgroundMusic.mp3";
    playAudio(); // Start playing the new music immediately
  };

  useEffect(() => {
    // Add a listener for any user interaction
    const handleUserInteraction = () => {
      let info_volume = Cookies.get("Volume");
      if (info_volume) {
        changeVolume(info_volume / 100);
      }
      playAudio();
      // Remove the event listener after the first interaction
      document.removeEventListener("click", handleUserInteraction);
    };

    // Attach the click event listener
    document.addEventListener("click", handleUserInteraction);

    // Cleanup the event listener if the component unmounts
    return () => {
      document.removeEventListener("click", handleUserInteraction);
    };
  }, []);

  return <AudioContext.Provider value={{ playAudio, pauseAudio, cancelMusic, volume, changeVolume, angpaoMusicOn, backgroundMusicOn }}>{children}</AudioContext.Provider>;
};

export const useAudio = () => useContext(AudioContext);
