import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

export const baseDomain = "https://doc.bwg688.co";
// export const baseDomain = "http://192.168.100.19:3002";

export const authApiBaseUrl = axios.create({
  baseURL: `${baseDomain}`,
});

export default function apiBaseUrl() {
  const api = axios.create({
    baseURL: `${baseDomain}`,
    headers: {
      Authorization: `${Cookies.get("Token")}`, // Set the token here
    },
    //withCredentials: true, // 添加此行以允许跨域请求携带 cookie
  });

  const onResponseSuccess = (response) => {
    return response;
  };

  const onResponseError = (error) => {
    if (!error.response) {
      Swal.fire({
        text: "Unable connect to server",
        icon: "error",
      });
    } else if (error?.code === "ERR_NETWORK") {
      Swal.fire({
        text: "Maintenance In Progress",
        icon: "error",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
    } else {
      if (error.response.status === 401) {
        if (window.location.pathname !== "/") {
          window.location.assign("/");
        }
      } else if (error.response.status === 307) {
        if (window.location.pathname !== "/maintenance") {
          window.location.assign("/maintenance");
        }
      }
    }
    return Promise.reject(error);
  };

  api.interceptors.response.use(onResponseSuccess, onResponseError);
  return api;
}
