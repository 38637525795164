import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import "./login.scss";
import { Button, Col, Form, Input, Row, message, Radio } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { playerLogin } from "../../services/player-api/PlayerServices";
import { setPlayerInfo } from "../../function/common";
import { useAudio } from "../../context/AudioContext";

function Login() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const [loadingScreen, setLoadingScreen] = useState(true);
  const { isLogin, setIsLogin, getPlayerActive, isVertical } = useContext(AuthContext);
  const { playAudio, cancelMusic, backgroundMusicOn } = useAudio();
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  useEffect(() => {
    cancelMusic();
    const timer = setTimeout(() => {
      setLoadingScreen(false);

      if (isLogin) {
        navigate("/lobby", { replace: true });
      }
    }, 1500); // 30000 milliseconds = 30 seconds

    // Cleanup function to clear the timeout if the component unmounts or item changes
    return () => clearTimeout(timer);
  });

  const handleLanguageChange = (e) => {
    const value = e.target.value;
    i18n.changeLanguage(value);
    setSelectedLanguage(value);
  };

  async function handleLogin(values) {
    try {
      setIsLoading(true);
      const result = await playerLogin({
        username: values.playerID,
        password: values.password,
      });

      if (result.playerID != undefined && result.playerID != "" && result.token != undefined && result.token != "") setIsLogin(true);

      let playerInfo = {
        playerID: result.playerID,
        username: values.playerID,
        token: result.token,
        music: result.music,
        lang: result.lang,
      };
      setPlayerInfo(playerInfo);
      backgroundMusicOn();
      return;
    } catch (err) {
      if (err?.response?.data?.message == "Invalid Username or Password") {
        message.error(t("invalidUsernameOrPassword"));
      } else if (err?.response?.data?.message == "Password Invalid") {
        message.error(t("passwordInvalid"));
      } else {
        message.error({ content: err?.response?.data?.message, key: err?.response?.data?.message });
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div
      className="login-container"
      style={{
        backgroundImage: `url(https://game-platform.sgp1.digitaloceanspaces.com/bwg/login-bg.png)`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Overlay Image */}
      <LazyLoad>
        <img
          src={isVertical ? "https://game-platform.sgp1.digitaloceanspaces.com/bwg/loading/BWG%20Loading%202.gif" : "https://game-platform.sgp1.digitaloceanspaces.com/bwg/loading/BWG%20Loading%201.gif"} // Replace with your overlay image path
          alt="Overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%", // Full width of the container
            height: "100%", // Full height of the container
            objectFit: "cover", // Cover the area without stretching
            zIndex: 5, // Lower than the button, higher than the iframe
            pointerEvents: "none", // Allow clicks to pass through to the iframe
            display: loadingScreen ? "" : "none",
          }}
        />
      </LazyLoad>
      <div className="login-logo">
        <img className="login-logo-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/logo.png" alt="" />
      </div>
      <div className="login-wrapper">
        <Form className="login-form" layout="vertical" form={form} onFinish={handleLogin} initialValues={{ language: "en" }}>
          <Row justify="center" align="middle">
            <Col xs={24}>
              <Form.Item name="playerID">
                <Input prefix={<UserOutlined />} placeholder={t("playerID")} size="large" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item name="password">
                <Input.Password prefix={<LockOutlined />} placeholder={t("password")} size="large" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item name="language">
                <Radio.Group onChange={handleLanguageChange}>
                  <Radio value="en">
                    <span style={{ color: "white", fontSize: "small" }}>{t("en")}</span>
                  </Radio>
                  <Radio value="my">
                    <span style={{ color: "white", fontSize: "small" }}>{t("my")}</span>
                  </Radio>
                  <Radio value="cn">
                    <span style={{ color: "white", fontSize: "small" }}>{t("cn")}</span>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item>
                <Button block htmlType="submit" type="primary" size="large" shape="round">
                  {t("login")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div>
        <img
          src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/apk_download.png"
          className="apk-download"
          hidden={window.location.hostname.split(".")[0] === "apph5"}
          alt="Twitter"
          onClick={() => {
            fetch("https://game-platform.sgp1.digitaloceanspaces.com/bwg/apk/version.json")
              .then((response) => {
                if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
              })
              .then((data) => {
                // Check if `data[0]` and `data[0].url` exist
                const url = data[0]?.url;
                if (url) {
                  const newWindow = window.open(url, "_blank");
                  if (newWindow) {
                    newWindow.focus();
                  } else {
                    console.error("Failed to open the new window. It might be blocked by the browser.");
                  }
                } else {
                  console.error("URL is not defined or is invalid.");
                }
              })
              .catch((error) => console.error("Error fetching data:", error));
          }}
        />
      </div>
    </div>
  );
}

export default Login;
