import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./gamecat.scss";
import { AuthContext } from "../../../../context/AuthContext";

function GameCat() {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const { isVertical, category, setCategory } = useContext(AuthContext);

    const gameCat = () => {
        switch (currentLanguage) {
          case 'my': // assuming 'ms' is the code for Malay
            return [
              "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/my/slot.png",
              "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/my/live.png",
              "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/my/recent.png",
            ];
          case 'cn': // assuming 'zh' is the code for Mandarin
            return [
            "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/cn/slot.png",
            "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/cn/live.png",
            "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/cn/recent.png",
            ]
          default:
            return [
              "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/slot.png",
              "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/live.png",
              "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/recent.png",
            ]
        }
    }

    const getImageSrc = () => {
      switch (currentLanguage) {
        case 'my': // assuming 'ms' is the code for Malay
          return "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/my/category.png";
        case 'cn': // assuming 'zh' is the code for Mandarin
          return "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/cn/category.png";
        default:
          return "https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/category.png";
      }
    };

    return (
        <div className={`${isVertical ? "v" : "h"}-game-cat-container`}>
            <div className="cat-title">
                <img className="cat-img" src={getImageSrc()} alt="" />
            </div>
            {gameCat()?.map((cat, index) => (
                <div key={index} className="cat" onClick={() => setCategory(cat?.split("/").slice(-1)[0].split(".")[0])}>
                    <img className={`cat-img ${cat?.split("/").slice(-1)[0].split(".")[0] === category}`} src={cat} alt="" />
                </div>
            ))}
        </div>
    );
}

export default GameCat;
