import React, { useContext, useState, useEffect } from "react";
import "./gamelist.scss";
import { message } from "antd";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { AuthContext } from "../../../../context/AuthContext";
import { getLobbyGame, launchGame } from "../../../../services/lobby-api/LobbyServices";
import { getPlayerAction } from "../../../../services/player-api/PlayerServices";
import ImageDownloader from "../../../../utils/ImageDownloader";
import BlurUpImage from "../../../../function/BlurUpImage";

function GameList() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isVertical, category } = useContext(AuthContext);

  const [gameList, setGameList] = useState(null);
  const [recentGameList, setRecentGameList] = useState(null);
  const [filteredGameList, setFilteredGameList] = useState(null);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        setIsLoading(true);
        const [lobbyData, recentData] = await Promise.all([getLobbyGame(), getPlayerAction({ playerID: Cookies.get("PlayerID") })]);
        setGameList(lobbyData);
        setRecentGameList(recentData);
      } catch (err) {
        setError(err.message || "Failed to fetch data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchGames();
  }, []);

  useEffect(() => {
    if (gameList) {
      if (category == "recent") {
        const recentGameIndexMap = new Map();
        recentGameList.forEach((recentGame, index) => {
          if (!recentGameIndexMap.has(recentGame.gameID)) {
            recentGameIndexMap.set(recentGame.gameID, index);
          }
        });

        const filteredGames = gameList
          .filter((game) => recentGameList.some((recentGame) => recentGame.gameID === game.gameID))
          .sort((a, b) => {
            return recentGameIndexMap.get(a.gameID) - recentGameIndexMap.get(b.gameID);
          })
          .slice(0, 30);

        setFilteredGameList(filteredGames);
      } else {
        setFilteredGameList(gameList.filter((game) => game.category === category));
      }
    }
  }, [gameList, category]);

  const startGame = async (gp, id) => {
    try {
      setIsLoading(true);
      var playerID = Cookies.get("PlayerID");

      if (playerID !== undefined || playerID != "") {
        const result = await launchGame({
          playerID: Cookies.get("PlayerID"),
          gameID: id,
          provider: gp,
          callback: `${window.location.protocol}//${window.location.host}/lobby`,
        });
        const item = {
          gameID: id,
          category: category,
          src: result.link,
        };
        navigate("/game", { state: { item } });
      }

      return;
    } catch (err) {
      if (err?.response?.status === 400) {
        message.warning(err?.response?.data?.message);
      }
      setError(err.message || "Failed to launch game");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`${isVertical ? "v" : "h"}-game-container`}>
      <div className="game-wrapper">
        {filteredGameList?.map((game, index) => (
          <div
            key={index}
            className="game"
            style={{
              margin: "5px", // Adds 5px space around each game item
            }}
            onClick={() => startGame(game.provider, game.gameID)}
          >
            <div className="game-img-container" style={{ position: "relative", textAlign: "center" }}>
              <BlurUpImage
                className="game-img"
                src={game.img}
                alt={game.title || "Game Image"}
                style={{
                  width: "256px",
                  height: "256px",
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <div
                className="game-img-reflection"
                style={{
                  height: "25px", // Height of the gap
                  overflow: "hidden",
                  position: "absolute",
                  bottom: "-25px",
                  left: 0,
                  right: 0,
                  transform: "scaleY(-1)", // Flip the reflection vertically
                  backgroundImage: `url(${game.img})`, // Use the same image
                  backgroundSize: "100% auto", // Maintain the width of the image
                  opacity: 1, // Adjust opacity for a reflection effect
                  backgroundPosition: "bottom", // Start from the bottom of the reflection area
                  filter: "brightness(0.4)",
                }}
              />
            </div>

            <div
              style={{
                height: "25px",
                position: "relative", // Keep the text positioned within the parent
                textAlign: "center",
                display: "block",
                whiteSpace: "nowrap", // Prevent text wrapping if needed
                overflow: "hidden", // Hide overflow text
                textOverflow: "ellipsis", // Add ellipsis for overflow text
                fontFamily: "'Arial Black', sans-serif",
                fontSize: "1rem", // Adjust size
                fontWeight: "bold", // Make it bold
                opacity: 1, // Keep text fully opaque
              }}
            >
              <div
                style={{
                  padding: "5px", // Optional: Add some padding for the text
                  borderRadius: "5px", // Optional: Round corners
                  display: "inline-block", // Ensures the background only covers the text
                }}
              >
                <span style={{ color: "gold", textShadow: "2px 2px black" }}>{game.gameName}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GameList;
