import React, { useEffect, useContext, useRef } from "react";
import LazyLoad from "react-lazyload";
import { useNavigate } from "react-router-dom";
import "./mainlayout.scss";
import Cookies from "js-cookie";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { AudioProvider } from "../../context/AudioContext";

function MainLayout() {
  const navigate = useNavigate();
  const { isVertical, isLogin } = useContext(AuthContext);

  useEffect(() => {
    if (isLogin !== undefined && isLogin !== null && isLogin !== "null" && !isLogin) {
      navigate("/");
    }
  }, []);

  return (
    <AudioProvider>
      <div
        className="layout-container"
        style={{
          backgroundImage: `url(https://game-platform.sgp1.digitaloceanspaces.com/bwg/${isVertical ? "v" : "h"}-bg.png)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundColor: "black",
        }}
      >
        <Outlet />
      </div>
    </AudioProvider>
  );
}

export default MainLayout;
