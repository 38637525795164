import { Form, Input, Modal, Checkbox, Slider, Radio, Button } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../context/AuthContext";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import "./settingsmodal.scss";
import { logout } from "../../../../services/player-api/PlayerServices"
import { updateSettings } from "../../../../services/lobby-api/LobbyServices"
import { setPlayerInfo } from "../../../../function/common";
import { useAudio } from '../../../../context/AudioContext';
import BlurUpImage from "../../../../function/BlurUpImage";

function SettingsModal({ settingsVisible, setSettingsVisible }) {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const [isLoading, setIsLoading] = useState(false);

    const [isMuted, setIsMuted] = useState(false);
    const [volume, setVolume] = useState(100);
    const [language, setLanguage] = useState('en');

    const { isVertical, setIsLogin } = useContext(AuthContext);
    const { changeVolume, pauseAudio } = useAudio();

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value); // Update the language state based on selected value
    };

    const getCurrentLanguage = () => {
      switch (currentLanguage) {
        case 'my': // assuming 'ms' is the code for Malay
          return "/my";
        case 'cn': // assuming 'zh' is the code for Mandarin
          return "/cn";
        default:
          return "";
      }
    };
    
    useEffect(() => {
        const savedVolume = Cookies.get("Volume");
        if (savedVolume) {
            setVolume(Number(savedVolume)); // Update the state with the cookie value
        }
        const savedLanguage = Cookies.get("Language");
        if (savedLanguage) {
            setLanguage(savedLanguage); // Update the state with the cookie value
        }
    }, []); // Empty dependency array ensures this runs only on mount

    async function handleSubmit() {
        try {
          setIsLoading(true);
          const result = await updateSettings(
            {
              playerID: Cookies.get("PlayerID"),
              music: volume,
              lang: language
            }
          );    

          setPlayerInfo({music: volume, lang: language});
          i18n.changeLanguage(Cookies.get("Language"))
          
          setSettingsVisible(false);
          return;
        } catch (err) {
          //setError(err.message || "Failed to get Balance");
        } finally {
          setIsLoading(false);
          window.location.reload();
        }
    }

    function handleCancel() {
        setSettingsVisible(false);
    }

    function handleLogout() {
        try {
          setIsLoading(true);
          Swal.fire({
              text: t("confirmToLogout"),
              icon: "info",
              showCancelButton: true,
              color: "#fff",
              background: "#434343",
              customClass: {
                  popup: 'swal-popup-border', // Custom class for popup
              },
          }).then(async (result) => {
              if (result.isConfirmed) {
                //const result = await logout(
                //  {
                //    playerID: Cookies.get("PlayerID")
                //  }
                //);    
                  Cookies.remove("PlayerID");
                  Cookies.remove("UserName");
                  Cookies.remove("Token");
                  Cookies.remove("Volume");
                  Cookies.remove("Language");
                  setIsLogin(false);
                  navigate("/");
              }
          });
          return;
        } catch (err) {
          //setError(err.message || "Failed to get Balance");
        } finally {
          setIsLoading(false);
        }
    }

    return   (
        isVertical ? (
            <Modal
                className="v-settings-modal-container"
                closeIcon={false}
                open={settingsVisible}
                onCancel={handleCancel}
                centered
                footer={null}
            >
                <BlurUpImage
                    src={"https://game-platform.sgp1.digitaloceanspaces.com/bwg/settings"+getCurrentLanguage()+"/MB3.0_Setting_VERT-05.png"}
                    alt="settings"
                    className="modal-image"
                />
                <Form className="form-overlay">
                    <Form.Item name="username" className="form-group">
                        <div className="form-row-username">
                            <img src={"https://game-platform.sgp1.digitaloceanspaces.com/bwg/settings"+getCurrentLanguage()+"/MB3.0_Setting_HORZ-05.png"} alt="Username" className="label-image" />
                            <span className="text">{Cookies.get("UserName")}</span>
                            <img 
                                src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/logoutbtn.png"
                                alt="Return Button"
                                className="logout-btn"
                                onClick={handleLogout}
                            />  
                        </div>
                    </Form.Item>
            
                    <Form.Item name="music" className="form-group">
                        <div className="form-row">
                            <img src={"https://game-platform.sgp1.digitaloceanspaces.com/bwg/settings"+getCurrentLanguage()+"/MB3.0_Setting_HORZ-06.png"} alt="Music" className="label-image" />
                            <Slider 
                                defaultValue={100} 
                                value={volume}
                                min={0} 
                                max={100} 
                                tooltip={{ open: false }} //settingsVisible }}
                                onChange={(value) => {setVolume(value); changeVolume(value/100);}} // Pass the value to setVolume
                            />
                        </div>
                    </Form.Item>
    
                    <Form.Item name="language" className="form-group">
                        <div className="form-row" style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                src={"https://game-platform.sgp1.digitaloceanspaces.com/bwg/settings"+getCurrentLanguage()+"/MB3.0_Setting_HORZ-08.png"}
                                alt="Language"
                                className="label-image"
                                style={{ marginRight: '8px' }}
                            />
                            <Radio.Group onChange={handleLanguageChange} value={language}>
                            <Radio.Group onChange={handleLanguageChange} value={language}>
                                <Radio value="en">
                                    <span style={{ color: 'white' }}>{t("en")}</span>
                                </Radio>
                                <Radio value="my">
                                    <span style={{ color: 'white' }}>{t("my")}</span>
                                </Radio>
                                <Radio value="cn">
                                    <span style={{ color: 'white' }}>{t("cn")}</span>
                                </Radio>
                            </Radio.Group>
                            </Radio.Group>
                        </div>
                    </Form.Item>
    
                    <Form.Item name="button" className="form-group">  
                        <img 
                            src={"https://game-platform.sgp1.digitaloceanspaces.com/bwg/settings"+getCurrentLanguage()+"/submit-btn.png"}
                            alt="Submit Button"
                            className="submit-btn"
                            onClick={handleSubmit}
                        />  
                        <img 
                            src={"https://game-platform.sgp1.digitaloceanspaces.com/bwg/settings"+getCurrentLanguage()+"/cancel-btn.png"}
                            alt="Cancel Button"
                            className="cancel-btn"
                            onClick={handleCancel}
                        />  
                    </Form.Item>
                </Form>
            </Modal>
        ) : (
            
            <Modal
                className="h-settings-modal-container"
                closeIcon={false}
                open={settingsVisible}
                onCancel={handleCancel}
                centered
                footer={null}
            >
                <BlurUpImage
                    src={"https://game-platform.sgp1.digitaloceanspaces.com/bwg/settings"+getCurrentLanguage()+"/MB3.0_Setting_HORZ-02.png"}
                    alt="settings"
                    className="modal-image"
                />
                <Form className="form-overlay">
                    <Form.Item name="username" className="form-group">
                        <div className="form-row-username">
                            <img src={"https://game-platform.sgp1.digitaloceanspaces.com/bwg/settings"+getCurrentLanguage()+"/MB3.0_Setting_HORZ-05.png"} alt="Username" className="label-image" />
                            <span className="text">{Cookies.get("UserName")}</span>
                            <img 
                                src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/logoutbtn.png"
                                alt="Return Button"
                                className="logout-btn"
                                onClick={handleLogout}
                            />  
                        </div>
                    </Form.Item>
            
                    <Form.Item name="music" className="form-group">
                        <div className="form-row">
                            <img src={"https://game-platform.sgp1.digitaloceanspaces.com/bwg/settings"+getCurrentLanguage()+"/MB3.0_Setting_HORZ-06.png"} alt="Music" className="label-image" />
                            <Slider 
                                defaultValue={100} 
                                value={volume}
                                min={0} 
                                max={100} 
                                tooltip={{ open: false }} //settingsVisible }}
                                onChange={(value) => {setVolume(value); changeVolume(value/100);}} // Pass the value to setVolume
                            />
                        </div>
                    </Form.Item>
    
                    <Form.Item name="language" className="form-group">
                        <div className="form-row" style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                src={"https://game-platform.sgp1.digitaloceanspaces.com/bwg/settings"+getCurrentLanguage()+"/MB3.0_Setting_HORZ-08.png"}
                                alt="Language"
                                className="label-image"
                                style={{ marginRight: '15px' }}
                            />
                            <Radio.Group onChange={handleLanguageChange} value={language}>
                                <Radio value="en">
                                    <span style={{ color: 'white', fontSize: "small" }}>{t("en")}</span>
                                </Radio>
                                <Radio value="my">
                                    <span style={{ color: 'white', fontSize: "small" }}>{t("my")}</span>
                                </Radio>
                                <Radio value="cn">
                                    <span style={{ color: 'white', fontSize: "small" }}>{t("cn")}</span>
                                </Radio>
                            </Radio.Group>
                        </div>
                    </Form.Item>
    
                    <Form.Item name="button" className="form-group">  
                        <img 
                            src={"https://game-platform.sgp1.digitaloceanspaces.com/bwg/settings"+getCurrentLanguage()+"/submit-btn.png"}
                            alt="Submit Button"
                            className="submit-btn"
                            onClick={handleSubmit}
                        />  
                        <img 
                            src={"https://game-platform.sgp1.digitaloceanspaces.com/bwg/settings"+getCurrentLanguage()+"/cancel-btn.png"}
                            alt="Cancel Button"
                            className="cancel-btn"
                            onClick={handleCancel}
                        />  
                    </Form.Item>
                </Form>
            </Modal>
        )
    );
}

export default SettingsModal;